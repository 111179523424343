import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { BehaviorSubject, combineLatest, EMPTY, of, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CommonComponentsModule } from '@app/common/common.module';
import { NotificationHandler } from '@app/common/helper/notification.handler';
import { LoadingState } from '@app/common/model';
import { User } from '@app/common/model/auth/user';
import { ClientWebhook, ClientWebhookEvent } from '@app/components/common/model/client';
import { ClientService } from '@app/components/common/service/client.service';
import { EventSubscriptionComponent } from './subscription/subscription.component';

@Component({
  standalone: true,
  selector: 'app-client-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    CommonComponentsModule,
    EventSubscriptionComponent,
    MatProgressSpinnerModule,
    NgFor,
    NgIf,
  ],
})
export class ClientEventsComponent implements OnInit, OnDestroy{
  protected clientService = inject(ClientService);
  readonly LoadingState = LoadingState;
  readonly webhooksLoadingState$ = new BehaviorSubject<LoadingState>(LoadingState.INIT);
  readonly webhooks$ = new BehaviorSubject<ClientWebhook[]>([]);
  readonly subscriptions$ = this.webhooks$.pipe(
    withLatestFrom(of(this.clientService.webhookEvents)),
    map(([webhooks, events]) => {
      const subscriptions: (Pick<ClientWebhook, 'events'> & Partial<Pick<ClientWebhook, 'id' | 'email'>>)[] = [];
      subscriptions.push(...webhooks.map(webhook => ({ ...webhook, active: true })));
      subscriptions.push(...events.filter(event => webhooks.every(webhook => !webhook.events.includes(event))).map(event => ({
        active: false,
        events: [event],
      })));

      return subscriptions;
    }),
  )

  readonly vm$ = combineLatest([this.webhooksLoadingState$, this.subscriptions$]).pipe(
    map(([loadingState, subscriptions]) => ({ loadingState, subscriptions }),)
  )

  private readonly onDestroy$ = new Subject<void>();

  @Input() user?: User;

  ngOnInit(): void {
    this.getClientWebhooks();
  }

  private getClientWebhooks() {
    this.webhooksLoadingState$.next(LoadingState.LOADING);
    this.clientService.getClientWebhooks().pipe(
      catchError(err => {
        this.webhooksLoadingState$.next(LoadingState.ERROR);
        NotificationHandler.handleError(err);
        return EMPTY;
      }),
      take(1),
      takeUntil(this.onDestroy$),
    ).subscribe(webhooks => {
      this.webhooksLoadingState$.next(LoadingState.LOADED);
      this.webhooks$.next(webhooks)
    });
  }

  protected createWebhook(email: string, event: ClientWebhookEvent) {
    this.webhooksLoadingState$.next(LoadingState.LOADING);
    this.clientService.createClientWebhook(email, event).pipe(
      catchError(err => {
        this.webhooksLoadingState$.next(LoadingState.ERROR);
        NotificationHandler.handleError(err);
        return EMPTY;
      }),
      take(1),
      takeUntil(this.onDestroy$),
    ).subscribe(webhook => {
      this.webhooksLoadingState$.next(LoadingState.LOADED);
      this.webhooks$.next([...this.webhooks$.getValue(), webhook]);
    })
  }

  protected deleteWebhook(webhookId: string) {
    this.webhooksLoadingState$.next(LoadingState.LOADING);
    this.clientService.deleteClientWebhook(webhookId).pipe(
      catchError(err => {
        this.webhooksLoadingState$.next(LoadingState.ERROR);
        NotificationHandler.handleError(err);
        return EMPTY;
      }),
      take(1),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.webhooksLoadingState$.next(LoadingState.LOADED);
      this.webhooks$.next(this.webhooks$.getValue().filter(w => w.id !== webhookId));
    })
  }

  ngOnDestroy(): void {
      this.onDestroy$.next();
      this.onDestroy$.complete();
  }
}
