import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@common/components/page-not-found/page-not-found.component';
import { AuthorizedGuard, UnauthorizedGuard } from '@app/helper/auth.guard';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [UnauthorizedGuard],
  },

  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        window.open(<string>route.data['url'], <string>route.data['target']);
      },
    },
  ],
})
export class AppRoutingModule {
}
