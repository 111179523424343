import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@app/common/model/auth/user';
import { Client } from '@app/components/common/model/client';
import { CommonComponentsModule } from '@app/common/common.module';
import { UserAccountData, UserAccountModalComponent } from '../user-account-modal/user-account-modal.component';

@Component({
  standalone: true,
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  imports: [
    CommonComponentsModule,
  ],
})
export class UserInfoComponent {
  private dialog = inject(MatDialog);

  @Input() user?: User;
  @Input() client?: Client;
  @Input() authorized!: boolean;

  @Output() signOutEvent = new EventEmitter();

  openClientSettings(): void {
    if (!this.user || !this.client) return;

    this.dialog.open<UserAccountModalComponent, UserAccountData>(UserAccountModalComponent, {
      width: '600px',
      data: { user: this.user, client: this.client }
    }).afterClosed().subscribe();
  }
}
