import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonComponentsModule } from '@common/common.module';
import { AppComponent } from '@app/app/app.component';
import { ComponentsModule } from '@app/components/components.module';
import { AuthService } from '@common/service/auth.service';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from '@app/helper/auth.interceptor';
import { AppRoutingModule } from '@app/app-routing.module';
import { InjectHelper } from '@common/helper/inject.helper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MarkdownModule } from 'ngx-markdown';
import { UserInfoComponent } from './components/user-account/user-info/user-info.component';

function initializeAppFactory(authService: AuthService): () => void {
  return () => authService.initAuth();
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        CommonComponentsModule,
        ComponentsModule,
        MatToolbarModule,
        MatSidenavModule,
        AppRoutingModule,
        MarkdownModule.forRoot({ loader: HttpClient }),
        UserInfoComponent], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(injector: Injector) {
    InjectHelper.setInjector(injector);
  }
}
