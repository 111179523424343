import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';

import { User } from '@app/common/model/auth/user';
import { Client } from '@app/components/common/model/client';
import { ClientEventsComponent } from './events/events.component';

export interface UserAccountData {
  user: User;
  client: Client;
}

@Component({
  standalone: true,
  selector: 'app-user-account-modal',
  templateUrl: './user-account-modal.component.html',
  styleUrls: ['./user-account-modal.component.scss'],
  imports: [
    ClientEventsComponent,
    MatTabsModule,
    NgFor,
    NgIf,
  ],
})
export class UserAccountModalComponent {
  protected data = inject<UserAccountData>(MAT_DIALOG_DATA);
  get user() {
    return this.data.user;
  }

  get client() {
    return this.data.client;
  }
}
