<div class="user-info">
  <div class="profile-info center">
    <avatar *ngIf="user"
            [letters]="user.getAvatarLetters()"></avatar>
  </div>
  <div *ngIf="user"
       class="profile-name">{{user.firstName}} {{user.lastName}}</div>
  <ng-container *ngIf="user && client">
    <div class="client-name">({{client.name}})</div>
    <div [style.text-align]="'center'">
      <app-button icon="settings"
                  tooltip="Client settings"
                  [secondary]="true"
                  (clicked)="openClientSettings()"></app-button>
    </div>
  </ng-container>

  <app-link *ngIf="authorized"
            class="sign-out"
            (click)="this.signOutEvent.emit()"
            label="Sign out"
            icon="logout"></app-link>
</div>
