<div class="client-events">
  <ng-container *ngIf="vm$ | async as vm">
    <ng-container *ngIf="vm.loadingState === LoadingState.LOADING">
      <div class="preloader">
        <mat-spinner color="primary"
                     diameter="40"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="vm.loadingState === LoadingState.LOADED">
      <ng-container>
        <div class="subscriptions">
          <ng-container *ngFor="let subscription of vm.subscriptions">
            <app-event-subscription [subscription]="subscription"
                                    [defaultEmail]="user?.email || ''"
                                    (createSubscription)="createWebhook($event.email, $event.event)"
                                    (deleteSubscription)="deleteWebhook($event)"></app-event-subscription>
          </ng-container>
        </div>
      </ng-container>

    </ng-container>
  </ng-container>
</div>
