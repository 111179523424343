import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatestWith, Subscription } from 'rxjs';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuItem } from '@common/model/menu-item';
import { User } from '@common/model/auth/user';
import { NotificationHandler } from '@common/helper/notification.handler';
import { AuthService } from '@common/service/auth.service';
import { Session } from '@common/model/auth/session';
import { MenuProviderService } from '@app/service/menu-provider.service';
import { environment } from '@environment';
import { Client } from '@app/components/common/model/client';
import { ClientService } from '@app/components/common/service/client.service';
import { LoadingState } from '@app/common/model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private DEFAULT_TITLE = 'B2B Portal';

  private routeEventsSubscription: Subscription | undefined;
  private breakpointChangeSubscription: Subscription | undefined;

  mainMenu: MenuItem[] = [];
  adminMenu: MenuItem[] = [];
  currentUrl?: string;

  readonly LoadingState = LoadingState;
  readonly userLoadingState$ = this.authService.userLoadingStage$;
  user?: User;
  session?: Session;
  client?: Client;

  dataLoaded = false;

  mobileView = false;

  envName = '';

  constructor(private authService: AuthService,
              private router: Router,
              private titleService: Title,
              private metaService: Meta,
              private menuProviderService: MenuProviderService,
              private clientService: ClientService,
              private breakpointObserver: BreakpointObserver) {
    this.titleService.setTitle(this.DEFAULT_TITLE);
  }

  ngOnInit(): void {
    this.envName = environment.env != 'prod' ? ` (${environment.env})` : '';

    this.routeEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        this.getUser();
      } else if (event instanceof RoutesRecognized) {
        const firstChild = event.state.root.firstChild;
        const routeData = firstChild ? firstChild.data : {};
        const innerChildData = firstChild?.firstChild ? firstChild.firstChild.data : null;

        let title = this.DEFAULT_TITLE;
        if (routeData['title']) {
          title = innerChildData && innerChildData['title']
            ? `${innerChildData['title']}${routeData['title'] ? ` | ${routeData['title']}` : ''} | ${title}`
            : `${routeData['title']} | ${title}`;
        }

        this.titleService.setTitle(title);
        this.metaService.updateTag({ property: 'og:title', content: title }, 'property=\'og:title\'');
        this.metaService.updateTag({ name: 'twitter:title', content: title }, 'name=\'twitter:title\'');
      }
    });

    this.breakpointChangeSubscription = this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        this.mobileView = state.matches;
      });
  }

  ngOnDestroy(): void {
    if (this.routeEventsSubscription) this.routeEventsSubscription.unsubscribe();
    if (this.breakpointChangeSubscription) this.breakpointChangeSubscription.unsubscribe();
  }

  get isAuthorized(): boolean {
    return !!this.user
      && !!this.session
      && this.session.isValid();
  }

  private getUser(): void {
    this.authService.getUser()
      .pipe(
        combineLatestWith(this.authService.getSession())
      )
      .subscribe(([user, session]) => {
        this.user = user;
        this.session = session;

        if (this.user && session.isValid()) {
          this.mainMenu = this.menuProviderService.mainMenu({ roles: session.roles, enterprise: user.enterprise });
          this.adminMenu = this.menuProviderService.adminMenu({ roles: session.roles });

          this.clientService.getClientConfig().subscribe({
            next: client => {
              this.client = client;
            },
            error: error => {
              NotificationHandler.handleError('Client details loading has failed', error);
            }
          });
        } else {
          this.mainMenu = [];
          this.adminMenu = [];
        }

        this.dataLoaded = true;
      });
  }

  async closeSidenav(sidenav: MatSidenav) {
    if (this.mobileView) {
      await sidenav.close();
    }
  }

  signOut(sidenav: MatSidenav) {
    this.authService.signOut().subscribe({
      next: () => {
        this.user = undefined;
        void this.closeSidenav(sidenav);
      }, error: error => {
        NotificationHandler.handleError(error);
      }, complete: () => {
        void this.router.navigate(['/auth/sign-in'], { replaceUrl: true });
      }
    });
  }
}
