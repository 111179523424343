<ng-container *ngIf="{ userLoadingState: userLoadingState$ | async } as obs">
  <div class="container">
    <ng-container *ngIf="obs.userLoadingState !== LoadingState.LOADING">
      <mat-toolbar color="primary"
                   class="top-toolbar">
        <mat-toolbar-row class="toolbar-row">
          <app-button *ngIf="isAuthorized && mobileView"
                      class="menu-btn"
                      icon="menu"
                      [secondary]="true"
                      (clicked)="sidenav.toggle()"></app-button>
          <div class="fill-space"></div>
          <div [class.mobile-title]="mobileView"><img src="./assets/logo-brand-rvpfactory-dark-v2.png"
                 alt="logo"
                 class="logo" /></div>
          <div>{{envName}}</div>
          <div class="fill-space"></div>
        </mat-toolbar-row>
      </mat-toolbar>

      <mat-sidenav-container class="sidenav-container"
                             autosize>
        <mat-sidenav #sidenav
                     class="main-sidenav"
                     [class.mobile]="mobileView"
                     [fixedInViewport]="mobileView"
                     [mode]="mobileView ? 'over' : 'side'"
                     [opened]="!mobileView && isAuthorized"
                     role="navigation">

          <app-user-info [user]="user"
                         [client]="client"
                         [authorized]="isAuthorized"
                         (signOutEvent)="signOut(sidenav)"></app-user-info>

          <hr>

          <mat-nav-list>
            <span *ngIf="!dataLoaded"
                  class="center">
              <mat-spinner color="primary"
                           [diameter]="30"></mat-spinner>
            </span>

            <menu-list-item *ngFor="let item of mainMenu"
                            class="menu-item no-focus"
                            [item]="item"
                            [currentUrl]="currentUrl || ''"
                            (childSelected)="closeSidenav(sidenav)"></menu-list-item>

            <ng-container *ngIf="adminMenu.length">
              <menu-list-item *ngFor="let item of adminMenu"
                              class="menu-item no-focus"
                              [item]="item"
                              [currentUrl]="currentUrl || ''"
                              (childSelected)="closeSidenav(sidenav)"></menu-list-item>
            </ng-container>
          </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content role="main"
                             class="main-content-holder"
                             [class.mobile]="mobileView">
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </ng-container>
    <ng-container *ngIf="obs.userLoadingState === LoadingState.LOADING">
      <div class="preloader">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </div>
</ng-container>
