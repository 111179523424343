<ng-container *ngIf="subscription">
  <mat-card [style.padding.px]="10"
            class="subscription"
            appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <code *ngFor="let event of subscription.events">{{ event.replaceAll('_', ' ') }}</code>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="subscription?.id; else inputTpl">
        <p>{{subscription.email }}</p>
      </ng-container>

      <ng-template #inputTpl>
        <form-input [control]="emailField"
                    [required]="true"
                    label="Email"
                    placeholder="User email..."
                    type="email"
                    [minLength]="5"
                    [maxLength]="128"></form-input>
      </ng-template>
    </mat-card-content>
    <mat-card-footer [style.padding]="'16px 0 0 0'"
                     [style.text-align]="'center'">
      <app-button *ngIf="subscription?.id"
                  label="Unsubscribe"
                  class="cta-btn center"
                  [destructive]="!!subscription.id"
                  (clicked)="deleteSubscription.emit(subscription.id)"></app-button>

      <app-button *ngIf="!subscription?.id"
                  label="Subscribe"
                  class="cta-btn center"
                  [disabled]="emailField.invalid"
                  (clicked)="createSubscription.emit({ email: emailField.value || '', event: subscription.events[0] })"></app-button>
    </mat-card-footer>
  </mat-card>
</ng-container>
